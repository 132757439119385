import EventEmitter from 'eventemitter3';
import { Swiper, Autoplay, Navigation, FreeMode, Mousewheel, Scrollbar, Thumbs } from 'swiper';

import SuperMapPoint from './point';
import SwiperSlide from './swiper-slide';
import { bindTap } from './util';

type MapSlideEventTypes = {
  locate: () => void;
  slideWillClose: () => void;
  slideDidClose: () => void;
};

export default class SuperMapSlide extends EventEmitter<MapSlideEventTypes> {
  /**
   * Point
   */
  private point: SuperMapPoint;

  /**
   * Slides that appear in slideshow
   */
  private slideshowSlides: SwiperSlide[] = [];

  /**
   * Slide element
   */
  private slide: HTMLElement;

  /**
   * Show a slide for a point
   *
   * @param point - Content to display on slide
   * @param map - Map element that we render the slide in
   */
  constructor(point: SuperMapPoint, map: HTMLElement) {
    super();

    this.point = point;

    this.slide = document.createElement('div');
    this.slide.classList.add('slide');

    map.prepend(this.slide);

    this.renderSlideshow();
    this.renderText();
    this.renderLocateButton();
    this.renderCloseButton();

    // $(this.slide).slideDown();
    this.slideDown();
  }

  /**
   * Close the slide
   */
  async close() {
    this.emit('slideWillClose');
    await this.slideUp();
    this.destroy();
    this.emit('slideDidClose');
  }

  /**
   * Destroy this slide object
   */
  private destroy() {
    this.slide.remove();

    for (const slide of this.slideshowSlides) {
      slide.destroy();
    }
  }

  private slideDown(): Promise<void> {
    return new Promise((resolve) => {
      const onEnd = () => {
        resolve();
      };

      this.slide.style.marginTop = '0';
      this.slide.addEventListener('transitionend', onEnd, { once: true });
    });
  }

  private slideUp(): Promise<void> {
    return new Promise((resolve) => {
      const onEnd = () => {
        resolve();
      };

      this.slide.style.marginTop = `-${this.slide.scrollHeight}px`;
      this.slide.addEventListener('transitionend', onEnd, { once: true });
    });
  }

  private renderText() {
    const text = document.createElement('div');
    text.classList.add('text');
    this.slide.append(text);

    // Append header
    const header = document.createElement('h1');
    header.innerHTML = this.point.info.name;
    text.append(header);

    // Append description
    const descriptionWrapper = document.createElement('div');
    descriptionWrapper.classList.add('description-wrapper');

    const description = document.createElement('div');
    description.classList.add('description');

    const descriptionText = document.createElement('div');
    descriptionText.classList.add('swiper-slide');
    descriptionText.innerHTML = this.point.info.description || '';

    description.append(descriptionText);
    descriptionWrapper.append(description);
    text.append(descriptionWrapper);

    new Swiper(description, {
      modules: [Scrollbar, FreeMode, Mousewheel],
      createElements: true,
      direction: 'vertical',
      slidesPerView: 'auto',
      freeMode: true,
      scrollbar: true,
      mousewheel: true,
    });
  }
  /**
   * Render slideshow on slide
   */
  private renderSlideshow() {
    const media = this.point.info.media;
    if (!media) {
      return;
    }

    const slideshowElement = document.createElement('div');
    slideshowElement.classList.add('slideshow');

    const galleryWrapper = document.createElement('div');
    galleryWrapper.classList.add('gallery-wrapper');
    slideshowElement.append(galleryWrapper);

    const gallery = document.createElement('div');
    gallery.classList.add('swiper', 'gallery');
    galleryWrapper.append(gallery);

    const thumbs = document.createElement('div');
    thumbs.classList.add('swiper', 'thumbs');
    slideshowElement.append(thumbs);

    this.slide.append(slideshowElement);

    for (const mediaElement of media) {
      const slide = new SwiperSlide(mediaElement);
      this.slideshowSlides.push(slide);

      gallery.append(slide.gallerySlide);
      thumbs.append(slide.thumbnailSlide);
    }

    const thumbSwiper = new Swiper('.thumbs', {
      modules: [FreeMode],
      spaceBetween: 10,
      createElements: true,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
      breakpoints: {
        640: {
          slidesPerView: 6,
        },
      },
    });

    const gallerySwiper = new Swiper('.gallery', {
      modules: [Autoplay, Navigation, Thumbs],
      spaceBetween: 10,
      createElements: true,
      thumbs: {
        swiper: thumbSwiper,
      },
      navigation: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
    });

    this.slideshowSlides[0].didShow(gallerySwiper);

    gallerySwiper.on('slideChange', (swiper) => {
      if (swiper.previousIndex !== undefined) {
        this.slideshowSlides[swiper.previousIndex].didHide();
      }

      this.slideshowSlides[swiper.activeIndex].didShow(gallerySwiper);
    });
  }

  /**
   * Render locate button on slide
   */
  private renderLocateButton() {
    const locateButton = document.createElement('a');
    locateButton.classList.add('locate');
    locateButton.textContent = 'Locate on Map';

    const mc = bindTap(locateButton);
    mc.on('tap', () => {
      this.emit('locate');
    });

    this.slide.append(locateButton);
  }

  /**
   * Render close button on slide
   */
  private renderCloseButton() {
    const closeButton = document.createElement('button');
    closeButton.classList.add('close');
    const closeText = document.createElement('h1');
    closeText.innerHTML = 'Close';
    closeButton.append(closeText);

    const mc = bindTap(closeButton);
    mc.on('tap', () => {
      this.close();
    });

    this.slide.append(closeButton);
  }
}
