import { Swiper } from 'swiper';

import PanoThumbnailOverlay from '../ui/thumbnail-pano.svg';
import VideoThumbnailOverlay from '../ui/thumbnail-video.svg';

import { MediaElement } from '.';

/**
 * Slide for the slideshow.  We render 2 versions of the slide.
 * One for use in the gallery
 * One for use as a thumbnail
 */
export default class SwiperSlide {
  /**
   * Gallery Slide
   */
  gallerySlide: HTMLDivElement;

  /**
   * Thumbnail Slide
   */
  thumbnailSlide: HTMLDivElement;

  /**
   * Video player for slide
   */
  private videoPlayer: jwplayer.JWPlayer | undefined;

  /**
   * KRPano interface for slide
   */
  private panoPlayer: krpano | undefined;

  /**
   * Media reference for slide
   */
  private media: MediaElement;

  /**
   * Constructor
   *
   * @param media - Media element to render on slide
   */
  constructor(media: MediaElement) {
    this.media = media;

    this.gallerySlide = document.createElement('div');
    this.thumbnailSlide = document.createElement('div');

    this.renderGallerySlide();
    this.renderThumbnailSlide();
  }

  destroy() {
    if (this.media.type === 'pano' && this.panoPlayer) {
      removepano(this.panoPlayer);
    }
  }

  /**
   * Called when the slide is shown
   *
   * @param swiper - Swiper instance we are displaying on
   */
  didShow(swiper: Swiper) {
    if (this.media.type === 'video' || this.media.type === 'pano') {
      swiper.autoplay.stop();
      swiper.allowTouchMove = false;
    } else {
      swiper.autoplay.start();
      swiper.allowTouchMove = true;
    }
  }

  /**
   * Called when the slide is hidden
   */
  didHide() {
    if (this.videoPlayer) {
      this.videoPlayer.stop();
    }
  }

  /**
   * Render a larger-format slide for the gallery
   */
  private renderGallerySlide() {
    this.gallerySlide.classList.add('swiper-slide');

    if (this.media.type === 'photo') {
      const image = document.createElement('img');
      image.src = this.media.path;
      this.gallerySlide.append(image);
    }

    if (this.media.type === 'pano') {
      const pano = document.createElement('div');
      pano.classList.add('krpano');
      this.gallerySlide.append(pano);

      embedpano({
        xml: this.media.path,
        target: pano,
        html5: 'auto',
        mobilescale: 1,
        onready: (krpano) => {
          this.panoPlayer = krpano;
        },
      });
    }

    if (this.media.type === 'video') {
      const video = document.createElement('div');
      this.gallerySlide.append(video);

      this.videoPlayer = jwplayer(video).setup({
        file: this.media.path,
        image: this.media.preview,
      });
    }
  }

  /**
   * Render a small slide for use as a thumbnail
   */
  private renderThumbnailSlide() {
    this.thumbnailSlide.classList.add('swiper-slide');

    if (this.media.type === 'photo') {
      const image = document.createElement('img');
      image.src = this.media.path;
      this.thumbnailSlide.append(image);
    }

    if (this.media.type === 'pano' && this.media.thumbnail) {
      const image = document.createElement('img');
      image.src = this.media.thumbnail;
      this.thumbnailSlide.append(image);

      const overlay = document.createElement('img');
      overlay.src = PanoThumbnailOverlay;
      overlay.classList.add('overlay');
      this.thumbnailSlide.append(overlay);
    }

    if (this.media.type === 'video') {
      if (this.media.thumbnail) {
        const image = document.createElement('img');
        image.src = this.media.thumbnail;
        this.thumbnailSlide.append(image);
      } else if (this.media.preview) {
        const image = document.createElement('img');
        image.src = this.media.preview;
        this.thumbnailSlide.append(image);
      }

      const overlay = document.createElement('img');
      overlay.src = VideoThumbnailOverlay;
      overlay.classList.add('overlay');
      this.thumbnailSlide.append(overlay);
    }
  }
}
