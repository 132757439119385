import { default as anime } from 'animejs';
import * as Hammer from 'hammerjs';

import SuperMapKey from './key';
import SuperMapPoint from './point';
import SuperMapPointPicker from './point-picker';
import SuperMapSlide from './slide';
import { bindTap, height, position, width } from './util';

/**
 * Map Zoom Settings
 */
export interface MapZoom {
  start: number;
  increment: number;
  min: number;
  max: number;
}

/**
 * X-Y Coordinate Pair
 */
export interface PointerCoordinate {
  x: number;
  y: number;
}

/**
 * Media element that can be displayed on a slide
 */
export interface MediaElement {
  type: string;
  path: string;
  preview?: string;
  thumbnail?: string;
}

/**
 * Map Point Configuration
 *
 * This is typically sourced from the mapInfo JSON file
 */
export interface MapInfo {
  id: string;
  keySeq?: number;
  name: string;
  description?: string;
  bubblePoint?: { top: number; left: number };
  path: string | string[];
  related?: string | string[];
  media?: MediaElement[];
  video?: string;
}

/**
 * Super Map!
 */
export default class SuperMap {
  /**
   * Root Map Container Element
   */
  private map: HTMLElement;

  /**
   * Map Content
   *
   * Images and Points.  This is the slippy layer that moves.
   */
  mapContent: HTMLElement;

  /**
   * Map Image
   */
  private mapImage: HTMLImageElement;

  /**
   * Map Points Layer
   */
  mapPointsLayer: SVGElement;

  /**
   * Actual Width of Map Image
   */
  private initialMapWidth: number;

  /**
   * Actual Height of Map Image
   */
  private initialMapHeight: number;

  /**
   * Map Points
   *
   * Mapped by id to SuperMapPoint object
   */
  private points: Record<string, SuperMapPoint> = {};

  /**
   * Map Info
   *
   * Loaded set of map points from the JSON configuration file
   */
  private mapInfo: MapInfo[];

  /**
   * Is the map image loaded?
   */
  private imageLoaded = false;

  /**
   * Are the points loaded?
   */
  private pointsLoaded = false;

  /**
   * Starting position for a pan.
   *
   * This should be the location of the initial press
   */
  private panStartCoordinates: PointerCoordinate = { x: 0, y: 0 };

  /**
   * Current position for a pan.
   *
   * This should be the current location of the press
   */
  private panCoordinates: PointerCoordinate = { x: 0, y: 0 };

  /**
   * Starting position of the image when we start to pan
   */
  private panStartCenter: PointerCoordinate = { x: 0, y: 0 };

  /**
   * Is panning occuring?
   */
  isPanning = false;

  /**
   * Store initial zoom when pinch starts
   */
  private pinchStartZoom = 0;

  /**
   * Map Zoom Settings.  These are calculated by the setZoomFromContainer() function.
   */
  private zoomDefaults: MapZoom = { start: 0, increment: 0, min: 0, max: 0 };

  /**
   * Current zoom level for the map
   */
  currentZoom = 0;

  /**
   * Slide
   */
  private slide: SuperMapSlide | undefined;

  /**
   * Point Picker
   */
  private pointPicker: SuperMapPointPicker | undefined;

  /**
   * Map Key
   */
  private mapKey: SuperMapKey | undefined;

  /**
   * Constructor
   */
  constructor(map: HTMLElement, mapInfo: MapInfo[]) {
    this.map = map;
    this.mapInfo = mapInfo;

    const mapContent = map.querySelector('.map-content');
    const mapImage = map.querySelector('#map-image');
    const pointsLayer = map.querySelector('#map-points');

    if (!(mapContent instanceof HTMLElement)) {
      throw 'Unable to locate .map-content element';
    }

    if (!(mapImage instanceof HTMLImageElement)) {
      throw 'Unable to locate #map-image element';
    }

    if (!(pointsLayer instanceof SVGElement)) {
      throw 'Unable to locate #map-points element';
    }

    this.mapContent = mapContent;
    this.mapImage = mapImage;
    this.mapPointsLayer = pointsLayer;

    this.initialMapWidth = this.imageWidth || 0;
    this.initialMapHeight = this.imageHeight || 0;

    this.setupKey();
    this.preloader();
    this.setZoomFromContainer();
    this.bindMapMove();
    this.setupZoomButtons();
    this.centerMap();

    window.addEventListener('resize', () => {
      this.setZoomFromContainer();
    });
  }

  /**
   * Current Map Height
   */
  private get mapHeight(): number {
    return height(this.map);
  }

  /**
   * Current Map Width
   */
  private get mapWidth(): number {
    return width(this.map);
  }

  /**
   * Current Image Height
   */
  private get imageHeight(): number {
    return height(this.mapImage);
  }

  /**
   * Current Image Width
   */
  private get imageWidth(): number {
    return width(this.mapImage);
  }

  /**
   * Restrict moving the map past the bounds
   *
   * @param x -
   * @param y -
   */
  private check(x: number, y: number): PointerCoordinate {
    const minY = this.mapHeight / 2;
    const maxY = this.imageHeight - this.mapHeight / 2;
    let limitY = y;
    if (y > maxY) {
      limitY = maxY;
    } else if (y < minY) {
      limitY = minY;
    }

    const minX = this.mapWidth / 2;
    const maxX = this.imageWidth - this.mapWidth / 2;
    let limitX = x;
    if (x > maxX) {
      limitX = maxX;
    } else if (x < minX) {
      limitX = minX;
    }

    return { x: limitX, y: limitY };
  }

  /**
   * Calculate the zoom extents and rezoom the map based on the starting
   * position.
   */
  private setZoomFromContainer() {
    let minZoom = this.mapHeight / this.initialMapHeight;
    if (minZoom * this.initialMapWidth < this.mapWidth) {
      minZoom = this.mapWidth / this.initialMapWidth;
    }

    let maxZoom = this.initialMapHeight / this.mapHeight;
    if (maxZoom * this.mapWidth > this.initialMapWidth) {
      maxZoom = this.initialMapWidth / this.mapWidth;
    }

    if (minZoom * 2 > maxZoom) {
      maxZoom = minZoom * 2;
    }

    const zoomIncrements = 12;
    const increment = (maxZoom - minZoom) / zoomIncrements;

    this.zoomDefaults = { start: minZoom, increment, max: maxZoom, min: minZoom };
    this.zoom(this.zoomDefaults.start);
  }

  /**
   * Position the map centered
   */
  private centerMap() {
    this.center(this.imageWidth / 2, this.imageHeight / 2);
  }

  /**
   * Center the map to to the new x-y coordinates.
   *
   * @param x -
   * @param y -
   * @param animate -
   */
  private center(x: number, y: number, animate = false) {
    const left = x * -1 + this.mapWidth / 2;
    const top = y * -1 + this.mapHeight / 2;

    if (animate === true) {
      anime({
        targets: this.mapContent,
        easing: 'linear',
        duration: 400,
        top: `${top}px`,
        left: `${left}px`,
      });
    } else {
      this.mapContent.style.top = `${top}px`;
      this.mapContent.style.left = `${left}px`;
    }
  }

  private getCenter(): PointerCoordinate {
    const mapPosition = position(this.mapContent);

    const x = (mapPosition.left - this.mapWidth / 2) * -1;
    const y = (mapPosition.top - this.mapHeight / 2) * -1;

    return { x, y };
  }

  /**
   * Display the loading spinner, fetch the mapInfo JSON file,
   * and trigger mapDidLoad() when the mapInfo and map image load.
   */
  private preloader() {
    this.mapImage.style.visibility = 'hidden';
    this.mapImage.alt = '';

    const loader = this.map.querySelector('.loader');
    if (loader instanceof HTMLElement) {
      loader.classList.remove('hidden');
    }

    this.mapImage.addEventListener('load', () => {
      this.imageLoaded = true;
      this.mapDidLoad();
    });

    if (this.mapImage.complete) {
      const event = new Event('load');
      this.mapImage.dispatchEvent(event);
    }
  }

  /**
   * Called after the map image and info are loaded
   */
  private mapDidLoad() {
    if (this.imageLoaded) {
      this.loadPoints();

      this.mapImage.style.visibility = 'visible';

      const loader = this.map.querySelector('.loader');
      if (loader instanceof HTMLElement) {
        loader.classList.add('hidden');
      }
    }
  }

  /**
   * Setup the map key
   */
  private setupKey() {
    this.mapKey = new SuperMapKey();

    this.mapKey.on('keyWillOpen', () => {
      this.closeAllBubbles(true);
      this.closeSlide();
    });

    this.mapKey.on('showPoint', (point) => {
      this.showPoint(point);
    });
  }

  /**
   * Load points retrieved from the JSON file and register them with the map key
   * and render them onto the map
   */
  private loadPoints() {
    if (this.pointsLoaded) {
      return;
    }

    if (this.mapInfo) {
      this.pointsLoaded = true;

      for (const location of this.mapInfo) {
        const point = new SuperMapPoint(this, location);
        this.points[location.id] = point;

        point.on('showPoint', () => {
          this.showPoint(point);
        });
      }

      // Register references to other points now that all are loaded
      for (const point of Object.values(this.points)) {
        point.registerReferences(this.points);
      }

      const pointsForKey = Object.values(this.points)
        .filter((value) => {
          return !!value.info.keySeq;
        })
        .sort((a, b) => {
          return (a.info.keySeq || 0) - (b.info.keySeq || 0);
        });

      for (const point of pointsForKey) {
        this.mapKey?.registerPoint(point);
      }
    }
  }

  /**
   * Update the map position
   */
  private update() {
    const start = this.panStartCoordinates;
    const now = this.panCoordinates;
    const moveX = start.x - now.x;
    const moveY = start.y - now.y;
    const y = this.panStartCenter.y + moveY;
    const x = this.panStartCenter.x + moveX;
    const check = this.check(x, y);

    this.center(check.x, check.y);
  }

  /**
   * Zoom the map in or out
   *
   * @param value - Direction to zoom, or absolute value to zoom to
   */
  private zoom(value: 'in' | 'out' | number) {
    const startPosition = this.getCenter();
    const startZoom = this.currentZoom;

    this.closeAllBubbles(true);

    if (value === 'in') {
      this.currentZoom += this.zoomDefaults.increment;
    } else if (value === 'out') {
      this.currentZoom -= this.zoomDefaults.increment;
    } else {
      this.currentZoom = value;
    }

    if (this.currentZoom + 0.001 > this.zoomDefaults.max) {
      this.currentZoom = this.zoomDefaults.max;
    }

    if (this.currentZoom - 0.001 < this.zoomDefaults.min) {
      this.currentZoom = this.zoomDefaults.min;
    }

    this.mapImage.style.width = `${Math.round(this.initialMapWidth * this.currentZoom)}px`;
    this.mapImage.style.height = `${Math.round(this.initialMapHeight * this.currentZoom)}px`;

    const zoomIn = document.querySelector('#zoom_in');
    if (zoomIn instanceof HTMLElement) {
      if (this.currentZoom >= this.zoomDefaults.max) {
        zoomIn.classList.add('disabled');
      } else {
        zoomIn.classList.remove('disabled');
      }
    }

    const zoomOut = document.querySelector('#zoom_out');
    if (zoomOut instanceof HTMLElement) {
      if (this.currentZoom <= this.zoomDefaults.min) {
        zoomOut.classList.add('disabled');
      } else {
        zoomOut.classList.remove('disabled');
      }
    }

    const scale = this.currentZoom / startZoom;
    const x = Math.round(startPosition.x * scale);
    const y = Math.round(startPosition.y * scale);
    const check = this.check(x, y);

    this.center(check.x, check.y);
  }

  /**
   * Setup pan event handlers to move the map
   */
  private bindMapMove() {
    const mc = new Hammer.Manager(this.mapContent, {
      domEvents: true,
    });

    mc.add([new Hammer.Pan({ direction: Hammer.DIRECTION_ALL }), new Hammer.Pinch()]);

    mc.on('panstart', (event: HammerInput) => {
      this.panStartCoordinates = event.center;
      this.panStartCenter = this.getCenter();
      this.isPanning = true;
      this.closeAllBubbles();
    });

    mc.on('pan', (event: HammerInput) => {
      if (this.isPanning) {
        this.panCoordinates = event.center;
        this.update();
      }
    });

    mc.on('panend', () => {
      this.isPanning = false;
    });

    mc.on('pinchstart', () => {
      this.pinchStartZoom = this.currentZoom;
    });

    mc.on('pinch', (event: HammerInput) => {
      if (this.isPanning !== true) {
        this.zoom(this.pinchStartZoom * event.scale);
      }
    });

    this.mapContent.addEventListener('wheel', (event) => {
      if (this.isPanning !== true) {
        this.zoom(this.currentZoom + event.deltaY * -0.001);
      }
    });
  }

  /**
   * Setup zoom buttons
   */
  private setupZoomButtons() {
    const zoomIn = document.querySelector('#zoom_in');
    if (zoomIn instanceof HTMLElement) {
      const mc = bindTap(zoomIn);
      mc.on('tap', () => {
        this.zoom('in');
      });
    }

    const zoomOut = document.querySelector('#zoom_out');
    if (zoomOut instanceof HTMLElement) {
      const mc = bindTap(zoomOut);
      mc.on('tap', () => {
        this.zoom('out');
      });
    }
  }

  /**
   * Clear selected points
   */
  private clearSelected() {
    for (const point of Object.values(this.points)) {
      point.unselect();
    }
  }

  /**
   * Close bubbles
   *
   * @param all - Close locate and select bubbles as well?
   */
  private closeAllBubbles(all = false) {
    if (all === true) {
      this.closePointPicker();
    }

    for (const point of Object.values(this.points)) {
      point.unhover(all);
    }
  }

  /**
   * Try and resolve point.  If given a point ID, we look it up, otherwise
   * we assume we have an instance of SuperMapPoint.  If there is a single
   * related point, we refer to that point instead.
   *
   * @param point - point to resolve
   * @returns resolved point
   */
  private resolvePoint(point: string | SuperMapPoint): SuperMapPoint {
    // Try and
    if (point instanceof SuperMapPoint) {
      if (point.related instanceof SuperMapPoint) {
        return this.resolvePoint(point.related);
      }

      return point;
    } else {
      const pointLookup = this.points[point];

      if (pointLookup) {
        return this.resolvePoint(pointLookup);
      }
    }

    throw `Unable to resolve point ${point}`;
  }

  /**
   * Show a point on the map.  Will recenter the map if necessary and show a slide or a point picker
   *
   * @param point -
   */
  private async showPoint(_point: string | SuperMapPoint) {
    const point = this.resolvePoint(_point);

    // Remove selection from all points
    this.clearSelected();

    // Remove all bubbles and popovers
    await this.closeSlide();
    this.closeAllBubbles(true);
    this.mapKey?.close();

    // Mark this point as selected
    point.select();

    // Recenter the map
    // const centerX = -point.element.offsetLeft + (this.mapWidth / 2 - width(point.element) / 2);
    // const centerY = -point.element.offsetTop + (this.mapHeight / 2 - height(point.element) / 2);
    // const center = this.check(centerX, centerY);
    // this.center(center.x, center.y, true);

    // If this point has multiple points to pick from...
    if (Array.isArray(point.related)) {
      this.showPointPicker(point);
    } else {
      this.showSlide(point);
    }
  }

  /**
   * Show the point picker
   *
   * @param point - Point to show picker for
   */
  private showPointPicker(point: SuperMapPoint) {
    this.pointPicker = new SuperMapPointPicker(point);

    this.pointPicker.on('showPoint', (point) => {
      this.showPoint(point);
    });

    this.pointPicker.on('pointPickerWillClose', () => {
      point.unselect();
      this.pointPicker = undefined;
    });
  }

  /**
   * Close the point picker
   */
  private closePointPicker() {
    this.pointPicker?.close();
  }

  /**
   * Show a slide for a point
   *
   * @param point -
   */
  private showSlide(point: SuperMapPoint) {
    if (this.slide) {
      console.error('Slide is still open');
      return;
    }

    this.slide = new SuperMapSlide(point, this.map);

    this.slide.on('locate', () => {
      this.closeAllBubbles(true);
      this.closeSlide();
      point.locate();
    });

    this.slide.on('slideWillClose', () => {
      point.unselect();
    });

    this.slide.on('slideDidClose', () => {
      this.slide = undefined;
    });
  }

  /**
   * Close the slide
   */
  private async closeSlide() {
    await this.slide?.close();
  }
}
