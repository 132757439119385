import { EventEmitter } from 'eventemitter3';

import SuperMapPoint from './point';
import { bindTap } from './util';

type MapPointPickerEventTypes = {
  pointPickerWillClose: () => void;
  pointPickerWillOpen: () => void;
  showPoint: (point: SuperMapPoint) => void;
};

export default class SuperMapPointPicker extends EventEmitter<MapPointPickerEventTypes> {
  /**
   * HTML element for point picker
   */
  private popup: HTMLElement;

  /**
   * Point Reference
   */
  private point: SuperMapPoint;

  /**
   * Show the point picker for a point
   *
   * @param point -
   */
  constructor(point: SuperMapPoint) {
    super();

    this.emit('pointPickerWillOpen');

    this.point = point;

    this.popup = document.createElement('div');
    this.popup.classList.add('bubble-select', point.id);

    this.popup.style.top = this.point.bubbleTop;
    this.popup.style.left = this.point.bubbleLeft;

    const headerElement = document.createElement('h1');
    headerElement.textContent = 'Click to view more:';
    this.popup.append(headerElement);

    if (Array.isArray(this.point.related)) {
      for (const related of this.point.related) {
        const buttonElement = document.createElement('button');
        buttonElement.innerHTML = related.title;
        this.popup.append(buttonElement);

        // On tap handler for list
        const mc = bindTap(buttonElement);
        mc.on('tap', () => {
          this.emit('showPoint', related);
        });
      }
    }

    const closeElement = document.createElement('button');
    closeElement.classList.add('close');
    closeElement.innerHTML = '<span class="icon-cross"></span>';
    this.popup.append(closeElement);

    point.map.mapContent.append(this.popup);

    // this.popup.style.top = `${point.element.offsetTop + height(point.element)}px`;
    // this.popup.style.left = `${point.element.offsetLeft}px`;

    setTimeout(() => {
      // this.popup.style.marginLeft = `${-(width(this.popup) / 2 - width(this.point.element) / 2)}px`;
    }, 0);

    const closeMc = bindTap(closeElement);
    closeMc.on('tap', this.close.bind(this));
  }

  /**
   * Close the point picker
   */
  close() {
    this.emit('pointPickerWillClose');

    this.popup.remove();
  }
}
