import EventEmitter from 'eventemitter3';

import { title } from './util';

import SuperMap, { MapInfo } from '.';

type MapPointEventTypes = {
  showPoint: () => void;
};

/**
 * Map Point
 */
export default class SuperMapPoint extends EventEmitter<MapPointEventTypes> {
  /**
   * Map that we render into
   */
  map: SuperMap;

  /**
   * Map point object from JSON file
   */
  info: MapInfo;

  /**
   * HTML element for map point
   */
  element: SVGGElement;

  /**
   * Path element for map point
   */
  path: SVGPathElement[] = [];

  /**
   * Bubble element for map point
   */
  bubble: HTMLDivElement | undefined;

  /**
   * Is point being located?
   */
  private _isLocating = false;

  /**
   * Is map selected?
   */
  private _isSelected = false;

  /**
   * Related points
   */
  // eslint-disable-next-line no-use-before-define
  related: SuperMapPoint | SuperMapPoint[] | undefined;

  /**
   * Constructor
   *
   * @param map - Map that we render into
   * @param info - Object info from JSON file
   */
  constructor(map: SuperMap, info: MapInfo) {
    super();

    this.map = map;
    this.info = info;

    this.element = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.element.id = this.id;

    this.render();
  }

  /**
   * Register references to any related points so that we have quick access
   *
   * @param points - complete list of points on map
   */
  registerReferences(points: Record<string, SuperMapPoint>) {
    const relatedNames = this.info.related;

    if (typeof relatedNames === 'string') {
      this.related = points[relatedNames];

      if (!this.related) {
        throw `Unable to located related element ${relatedNames} for ${this.id}`;
      }
    } else if (Array.isArray(relatedNames)) {
      this.related = [];

      for (const point of relatedNames) {
        const lookup = points[point];

        if (!lookup) {
          throw `Unable to located related element ${point} for ${this.id}`;
        }

        this.related.push(lookup);
      }
    }
  }

  /**
   * Formatted title
   */
  get title(): string {
    return title(this.info.name);
  }

  /**
   * Unique ID for this point
   */
  get id(): string {
    return this.info.id;
  }

  /**
   * Bubble left position
   */
  get bubbleLeft(): string {
    return `${(this.info.bubblePoint?.left || 0) * this.map.currentZoom * (2125 / 1224)}px`;
  }

  /**
   * Bubble top position
   */
  get bubbleTop(): string {
    return `${(this.info.bubblePoint?.top || 0) * this.map.currentZoom * (2125 / 1224)}px`;
  }

  /**
   * Render the point onto the map.
   */
  render() {
    this.element.classList.add('point');
    this.element.style.zIndex = '2';
    this.map.mapPointsLayer.append(this.element);

    const paths = Array.isArray(this.info.path) ? this.info.path : [this.info.path];

    for (const path of paths) {
      const p = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      p.setAttribute('d', path);
      this.element.prepend(p);
      this.path.push(p);
    }

    this.bindPoint();
  }

  /**
   * Setup handlers
   */
  private bindPoint() {
    const mc = new Hammer.Manager(this.element, {
      domEvents: true,
    });

    mc.add(new Hammer.Tap());
    mc.add(new Hammer.Press());

    mc.on('tap', () => {
      this.emit('showPoint');
    });

    mc.on('press', () => {
      this.hover();
    });

    mc.on('pressup', () => {
      this.unhover();
    });

    this.element.addEventListener('mouseover', () => {
      if (this.map.isPanning === false) {
        this.hover();
      }
    });

    this.element.addEventListener('mouseout', () => {
      this.unhover();
    });
  }

  /**
   * Is the point selected?
   */
  get isSelected() {
    return this._isSelected;
  }

  /**
   * Select this point
   */
  select() {
    this._isSelected = true;
    this.element.classList.add('selected');
  }

  /**
   * Unselect this point
   */
  unselect() {
    this._isSelected = false;
    this.element.classList.remove('selected');
  }

  /**
   * Display the point's location and name
   *
   * Bubble will fade out after 4 seconds
   */
  locate() {
    this._isLocating = true;
    this.hover();

    setTimeout(() => {
      this.fadeBubble();
      this._isLocating = false;
    }, 4000);
  }

  /**
   * Select and decorate the point with a bubble label
   */
  hover() {
    this.select();
    this.showBubble();
  }

  /**
   * Unselect the point and immediately hide the bubble
   *
   * @param force - Should locating bubbles be removed as well?
   */
  unhover(force = false) {
    if (force !== true && this._isLocating === true) {
      return;
    }

    this.unselect();
    this.destroyBubble();
  }

  /**
   * Unselect the point and fade out the bubble
   */
  fadeBubble() {
    this.unselect();

    if (this.bubble) {
      this.bubble.addEventListener(
        'transitionend',
        () => {
          this.destroyBubble();
        },
        { once: true }
      );

      this.bubble?.classList.remove('visible');
    }
  }

  /**
   * Remove the bubble element from the page
   */
  private destroyBubble() {
    this.bubble?.remove();
    this.bubble = undefined;
  }

  /**
   * Render a bubble for this point.  If there already is a bubble, we bail
   */
  private showBubble() {
    if (this.bubble) return;

    this.bubble = document.createElement('div');
    this.bubble.classList.add('bubble');
    this.bubble.innerHTML = this.title;

    this.bubble.style.top = this.bubbleTop;
    this.bubble.style.left = this.bubbleLeft;

    this.map.mapContent.append(this.bubble);

    setTimeout(() => {
      this.bubble?.classList.add('visible');
    }, 10);
  }
}
