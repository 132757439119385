import * as Hammer from 'hammerjs';

/**
 * Remove \<em\> elements from title string
 *
 * @param text - title string
 * @returns title string
 */
export function title(text: string): string {
  const element = document.createElement('span');
  element.innerHTML = text;
  // eslint-disable-next-line unicorn/no-array-for-each
  element.querySelectorAll('em').forEach((emElement) => {
    emElement.remove();
  });
  return element.textContent || '';
}

/**
 * Retrieve width for an element
 *
 * @param element - Element to get width for
 * @returns width
 */
export function width(element: HTMLElement): number {
  return Number.parseFloat(getComputedStyle(element).width.replace('px', ''));
}

/**
 * Retrieve height for an element
 *
 * @param element - Element to get height for
 * @returns height
 */
export function height(element: HTMLElement): number {
  return Number.parseFloat(getComputedStyle(element).height.replace('px', ''));
}

/**
 * Get the position as a left, top pair
 *
 * @param element - Element to get position for
 * @returns top and left position of the element
 */
export function position(element: HTMLElement): { left: number; top: number } {
  return { left: element.offsetLeft, top: element.offsetTop };
}

/**
 * Setup a new Hammer tap handler for the passed element
 *
 * @param element - Element to bind touch for
 * @returns Hammer Manager for element
 */
export function bindTap(element: HTMLElement): HammerManager {
  const mc = new Hammer.Manager(element, {});
  mc.add(new Hammer.Tap());

  return mc;
}
