import MapInfo from './map.json';
import Supermap from './supermap';
import './map.scss';

function hideAddressBar() {
  setTimeout(() => {
    window.scrollTo(0, 1);
  }, 100);
}

hideAddressBar();

if (!/msie/i.test(navigator.userAgent)) {
  window.addEventListener('load', () => {
    if (!window.pageYOffset) {
      hideAddressBar();
    }
  });
}

const map = document.querySelector('.map');
if (map instanceof HTMLElement) {
  new Supermap(map, MapInfo);
}
