import EventEmitter from 'eventemitter3';

import SuperMapPoint from './point';
import { bindTap } from './util';

type MapKeyEventTypes = {
  keyWillOpen: () => void;
  keyWillClose: () => void;
  showPoint: (point: string | SuperMapPoint) => void;
};

/**
 * Map Key Controller
 */
export default class SuperMapKey extends EventEmitter<MapKeyEventTypes> {
  /**
   * Map Dropdown Select Element
   */
  private mapSelect: HTMLSelectElement;

  /**
   * Map Key Element
   */
  private mapKey: HTMLElement;

  /**
   * Map Key Open Button
   */
  private mapKeyOpen: HTMLElement;

  /**
   * Map Key Close Button
   */
  private mapKeyClose: HTMLElement;

  /**
   * Setup the map key
   */
  constructor() {
    super();

    const mapSelect = document.querySelector('.map-key-select');
    const mapKey = document.querySelector('.map-key');
    const mapKeyOpen = document.querySelector('.map-key .key-btn .key-open-btn');
    const mapKeyClose = document.querySelector('.map-key .key-btn .key-close-btn');

    if (
      !(
        mapSelect instanceof HTMLSelectElement &&
        mapKey instanceof HTMLElement &&
        mapKeyOpen instanceof HTMLElement &&
        mapKeyClose instanceof HTMLElement
      )
    ) {
      throw 'Unable to setup map key';
    }

    this.mapSelect = mapSelect;
    this.mapKey = mapKey;
    this.mapKeyOpen = mapKeyOpen;
    this.mapKeyClose = mapKeyClose;

    // Mobile Key open/close functionality
    this.mapSelect.addEventListener('change', () => {
      this.emit('showPoint', this.mapSelect.value);
      this.mapSelect.value = '';
    });

    // Desktop/Tablet Map Key open/close functionality
    const openMc = bindTap(this.mapKeyOpen);
    openMc.on('tap', this.open.bind(this));
    const closeMc = bindTap(this.mapKeyClose);
    closeMc.on('tap', this.close.bind(this));
  }

  /**
   * Register a point into the key
   *
   * @param point - Point to register
   */
  registerPoint(point: SuperMapPoint) {
    // Append to select menu
    const optionElement = document.createElement('option');
    optionElement.innerHTML = point.title;
    optionElement.value = point.id;
    this.mapSelect.append(optionElement);

    // Append to list
    const linkElement = document.createElement('button');
    linkElement.innerHTML = point.title;
    const listElement = document.createElement('li');
    listElement.append(linkElement);
    this.mapKey.append(listElement);

    // On tap handler for list
    const mc = bindTap(linkElement);
    mc.on('tap', () => {
      this.emit('showPoint', point);
    });
  }

  /**
   * Close the map key
   */
  close() {
    this.emit('keyWillClose');

    this.mapKey.classList.remove('opened');
  }

  /**
   * Open the map key
   */
  open() {
    this.emit('keyWillOpen');

    this.mapKey.classList.add('opened');
  }
}
